$('.team-carousel').owlCarousel({
  loop: true,
  autoplayHoverPause: true,
  items: 5,
  margin: 20,
  autoplay: true,
  stagePadding: 0,
  center: false,
  nav: true,
  dots: true,
  navText: [
    '<div class="gal-arrow gal-prev"><i class="fa-light fa-circle-chevron-left pre-header-text fa-2xl"></i></div>',
    '<div class="gal-arrow gal-next"><i class="fa-light fa-circle-chevron-right pre-header-text fa-2xl"></i></div>',
    ],
  responsive: {
  0: {
    items: 1,
  },
  600: {
    items: 2,
  },
  1000: {
    items: 3,
  },
},
});

$('.property-images-carousel').owlCarousel({
    loop: true,
    autoplayHoverPause: true,
    items: 5,
    margin: 20,
    autoplay: false,
    stagePadding: 0,
    center: true,
    nav: true,
    dots: true,
    lazyLoad: true,
    navText: [
    '<div class="gal-arrow gal-prev"><i class="fa-light fa-circle-chevron-left pre-header-text fa-2xl"></i></div>',
    '<div class="gal-arrow gal-next"><i class="fa-light fa-circle-chevron-right pre-header-text fa-2xl"></i></div>',
    ],
    responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
});


$('.archive-property').owlCarousel({
    loop: true,
    items: 5,
    margin: 20,
    autoplay: false,
    stagePadding: 0,
    center: true,
    nav: true,
    dots: true,
    lazyLoad: true,
    animateOut: 'fadeOut',
    navText: [
      '<div class="gal-arrow gal-prev"><i class="fa-light fa-circle-chevron-left text-white rounded-circle bg-primary fashadow fa-2xl"></i></div>',
      '<div class="gal-arrow gal-next"><i class="fa-light fa-circle-chevron-right text-white rounded-circle bg-primary fashadow fa-2xl"></i></div>',
      ],
    responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
});

$('.home-intro').owlCarousel({
    loop: true,
    items: 6,
    margin: 2,
    autoplay: true,
    stagePadding: 0,
    center: true,
    nav: false,
    dots: false,
    lazyLoad: true,
    responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
  },
});





// $.fn.isOnScreen = function () {
//   var viewport = {
//       top: $(window).scrollTop(),
//       left: $(window).scrollLeft()
//   };
//   viewport.right = viewport.left + $(window).width();
//   viewport.bottom = viewport.top + $(window).height();
//   var bounds = this.offset();
//   bounds.right = bounds.left + this.outerWidth();
//   bounds.bottom = bounds.top + this.outerHeight();
//   return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
// };

//ajax bits for property filter
// function load_script() {
//   wp_enque_script('ajax', get_template_directory_uri() .'/assets/js/scripts.js', array('jquery'), NULL, true);
// }