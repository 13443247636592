/* eslint-disable */ 
$( function()
{
  let property_id = $('#property_id').val();
  let base_url = '/wp-json/availability/calendarAvailability?property_id='+property_id;
  var nights = 7;
  var changed = false;
  var changedDate = '';

  var default_start_date = $('#start_date').val();
  var availability = getAvailability();
  getPrices(default_start_date,'', nights);

  $('#nights').on('change', function()
  {
    nights = $(this).val();

    // Get The Date From The Calendar
    getPrices(changedDate, '', nights);
  });

  $( '.calendar' ).datepicker(
    {
      firstDay: 1,
      minDate: 0,
      changeMonth: true,
      changeYear: true,
      maxDate: "+24M",
      dateFormat: 'yy-mm-dd',
      beforeShowDay: function(date)
      {
        var string = jQuery.datepicker.formatDate('yy-mm-dd', date);

        if ($.inArray(string, availability[0]) != -1)
        {
          return [true, 'ui-datepicker-unselectable', ''];
        }
        else
        {
          if ($.inArray(string, availability[1]) != -1)
          {
            return [true, 'bookable-day', ''];
          }
          else
          {
            return [false, 'available-day', ''];
          }
        }
      },
      onChangeMonthYear: function (y, m)
      {
        // JS > PHP DateTime
        if(m < 10)
        {
          m = '0' + m;
        }

        // Make a date variable
        changedDate = y + '-' + m + '-01';

        changed = true;

        // Get nights value...
        var nights = $('#nights').find(':selected').val();

        // Update Our Array of availability
        availability = getAvailability(changedDate);
        prices = getPrices(changedDate, '', nights);
      },
      onSelect: function(dateText, inst)
      {
        $('#selected-date').val(dateText);
      }
    }
  );

  function getAvailability(start, end)
  {
    let property_id = $('#property_id').val();
    let base_url = '/wp-json/availability/calendarAvailability?property_id=' + property_id;

    if (start)
    {
      base_url += '&start_date=' + start
    }

    var disabled_dates = [];
    var available_dates = [];

    $.ajax(
    {
      url: base_url,
      dataType: 'json',
      type: 'GET',
      success: function(data)
      {
        $.each(data, function(key, value)
        {
          // Not Bookable, But Booked (Available Date)
          if(!value.bookable && value.booked)
          {
            disabled_dates.push(key);
          }
          else
          {
            if(value.bookable)
            {
              available_dates.push(key);
            }
          }
        })

        // Refresh t3h datepicker, we have our data
        $('.calendar').datepicker('refresh');

        if(default_start_date !== '' && changed === false)
        {
          $('.calendar').datepicker( 'setDate' , default_start_date );
        }
      }
    });

    return [disabled_dates, available_dates];
  }

  function getPrices(start, end, nights)
  {
    let property_id = $('#property_id').val();
    let base_url = '/wp-json/availability/stayPrices?property_id=' + property_id+'&nights='+nights;

    if (start)
    {
      base_url += '&start_date=' + start
    }

    var form_url = 'https://secure.supercontrol.co.uk/availability/booking_insert.asp';

    var pricing_div = $('.pricing');

    // Clear Pricing Div - You might want to pop in some loader or something in here
    // pricing_div.html('<div class="spinner-border text-center" role="status">\n' +
    //   '  <span class="visually-hidden">Loading...</span>\n' +
    //   '</div>');

    pricing_div.html('<div class="d-flex align-items-center">\n' +
    '<strong>Loading prices...</strong>\n'+
    '<div class="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></div>\n' +
    '</div>');

    $.ajax(
      {
        url: base_url,
        dataType: 'json',
        type: 'GET',
        success: function (data)
        {
          // Clear The Availability Div
          $(pricing_div).html('');

          if(data.length > 0)
          {
            $.each(data, function(key, value)
            {
              pricing_div.append('<form target="_blank" method="get" action="'+form_url+'" class="card mb-2">' +
                '<input type="hidden" name="ownerID" value="3848"> ' +
                '<input type="hidden" name="siteID" value="14140"> ' +
                '<input type="hidden" name="cottageID" value="cottageID_'+property_id+'">' +
                '<input type="hidden" name="date_sb" value="'+ value.dateString +'"> ' +
                '<input type="hidden" name="numberpeople" value="0"> ' +
                '<input type="hidden" name="Cchildren" value=""> ' +
                '<input type="hidden" name="Cinfants" value=""> ' +
                '<div class="row">' +
                '<div class="col-md-6">' +
                '<p class="result-nights">'+ value.nights +' Nights</p>' +
                '<p class="result-date">'+ value.friendly_date +'</p>' +
                '</div>' +
                '<div class="col-md-6">' +
                '<button type="submit" class="btn btn-primary">Book Now &pound;'+ value.price +'</button>' +
                '</div>' +
                '</div>' +
                '</form>');
            });
          }
          else
          {
            // No Pricing Data - Pop something in here
            $(pricing_div).html('<p class="text-danger">No dates found for this month</p>');
          }
        }
      });
  }
});