// import external dependencies
import 'jquery';
import 'jquery-ui/dist/jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';

// Import everything from autoload
import './autoload/_bootstrap.js'
import 'owl.carousel';
import 'pg-calendar';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import header from './routes/header';
import './calendar';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  //header
  header,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());


// Function to initialize Owl Carousel
function initializeOwlCarousel() {
  $('.owl-carousel').owlCarousel({
    loop: true,
    items: 5,
    margin: 20,
    autoplay: false,
    stagePadding: 0,
    center: true,
    nav: true,
    dots: true,
    lazyLoad: true,
    animateOut: 'fadeOut',
    navText: [
      '<div class="gal-arrow gal-prev"><i class="fa-light fa-circle-chevron-left text-white rounded-circle bg-primary fashadow fa-2xl"></i></div>',
      '<div class="gal-arrow gal-next"><i class="fa-light fa-circle-chevron-right text-white rounded-circle bg-primary fashadow fa-2xl"></i></div>',
      ],
    responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
  });
}

// Initialize on page load
$(document).ready(function() {
  initializeOwlCarousel();
});

// Reinitialize when FacetWP finishes loading
$(document).on('facetwp-loaded', function() {
  initializeOwlCarousel();
});





// function sorturl() {
//   var url = window.location.href;
//   var paginating = url.includes('page');

//   if(paginating) {
//     //append &
//   }

//   document.getElementById('property-order-form').submit();
// }