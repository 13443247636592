$('.testimonial-carousel').owlCarousel({
    loop: true,
    autoplayHoverPause: true,
    items: 5,
    margin: 20,
    autoplay: true,
    stagePadding: 0,
    center: true,
    nav: true,
    dots: true,
    navText: [
    '<i class="fa-solid fa-arrow-left-long"></i>',
    '<i class="fa-solid fa-arrow-right-long"></i>',
    ],
    responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
  });