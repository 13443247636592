   // var stickyElem = document.querySelector('.calendar-container');
     
   //  /* Gets the amount of height
   //  of the element from the
   //  viewport and adds the
   //  pageYOffset to get the height
   //  relative to the page */
   //  var currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
   //  window.onscroll = function() {
         
   //      /* Check if the current Y offset
   //      is greater than the position of
   //      the element */
   //      if(window.pageYOffset > currStickyPos) {
   //          stickyElem.style.position = 'fixed';
   //          stickyElem.style.top = '20px';
   //          stickyElem.classList.add('float-cal');

   //      } else {
   //          stickyElem.style.position = 'relative';
   //          stickyElem.style.top = 'initial';
   //          stickyElem.classList.remove('float-cal');
   //      }
   //  }



        // console.log($('body').find('.day'));
// $(window).on('load', function() {
//         $(document).unbind('click').on('click', '.day', function() {
//             $('.day').removeClass('current-day');
//             $(this).addClass('current-day');
//             console.log($(this));
//             if($(this).hasClass('current-day')){
//                 console.log('maclass');
//             }
//         }); 
//     });